@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-row;
  justify-content: center;
  align-items: center;
  gap: var(--space-50);
}

.dot1,
.dot2,
.dot3 {
  height: 8px;
  width: 8px;
  border-radius: var(--radius-round);
  background-color: currentColor;
  opacity: 0;
  animation: loadingDot 1.5s infinite linear;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.15s;
}

.dot3 {
  animation-delay: 0.3s;
}

@keyframes loadingDot {
  // 0.3s
  20% {
    opacity: 1;
  }
  // 0.9s
  60% {
    opacity: 1;
  }
  // 1.2s
  80% {
    opacity: 0;
  }
}
