@import "@alltrails/shared/styles/landsEnd.scss";
// $shadow lets us add to any existing box-shadows and defaults to no box-shadow
@mixin focusOutline($color, $shadow: 0 0) {
  &:not(.loading):focus-visible {
    box-shadow:
      $shadow,
      0 0 0 var(--borders-weight-lg) var($color) inset;
  }
}

@mixin activeOrHover {
  &:not(.loading):not(.disabled) {
    &:active {
      @content;
    }

    @include hover {
      @content;
    }
  }
}

.button {
  @include removeDefaultButtonStyles;
  position: relative; // Required so loading dots are positions properly
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0; // Needed to remove default margin on some browsers when an anchor tag is rendered
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-round);
  cursor: pointer;
  transition: all 0.2s ease-out;

  @include focusOutline(--color-border-focus-default);

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  &:not(.loading):not(.disabled):active {
    transform: scale(0.96);
  }

  svg {
    flex-shrink: 0;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.sm {
  @include text-100-bold;
  height: 36px;
  padding: 0 var(--space-200);

  &.lessPaddingLeft {
    gap: var(--space-100);
    padding-left: var(--space-150);
    padding-right: var(--space-200);
  }

  .plusBadge {
    right: -2px;
  }
}

.md {
  @include text-200-bold;
  height: 48px;
  padding: 0 var(--space-300);

  &.lessPaddingLeft {
    gap: var(--space-150);
    padding-left: var(--space-200);
    padding-right: var(--space-300);
  }
}

.lg {
  @include text-300-bold;
  height: 56px;
  padding: 0 var(--space-300);

  &.lessPaddingLeft {
    gap: var(--space-150);
    padding-left: var(--space-200);
    padding-right: var(--space-300);
  }
}

.full {
  width: 100%;
}

.fullOnMobile {
  @include maxWidth($breakpoint-640) {
    width: 100%;
  }
}

.default {
  background-color: var(--color-neutral-100-a);
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-neutral-200-a);
  }

  @include focusOutline(--color-border-focus-default);
}

.destructive {
  background-color: transparent;
  color: var(--color-text-error);

  @include activeOrHover {
    background-color: var(--color-background-primary-hover);
  }
}

.elevated {
  background-color: var(--color-background-primary);
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-background-primary-hover);
  }

  &.sm {
    box-shadow: var(--shadow-100);
    @include focusOutline(--color-border-focus-default, var(--shadow-100));
  }

  &.md {
    box-shadow: var(--shadow-200);
    @include focusOutline(--color-border-focus-default, var(--shadow-200));
  }

  &.lg {
    box-shadow: var(--shadow-300);
    @include focusOutline(--color-border-focus-default, var(--shadow-300));
  }
}

.flat {
  background-color: transparent;
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-neutral-100-a);
  }
}

.inverse {
  border: var(--borders-weight-md) solid var(--color-border-interactive);
  color: var(--color-text-primary-inverse);

  @include activeOrHover {
    border-color: var(--color-background-primary);
    background-color: var(--color-background-primary);
    color: var(--color-text-primary);
  }
}

.primary {
  background-color: var(--color-interactive-primary);
  color: var(--color-text-primary-inverse);

  @include activeOrHover {
    background-color: var(--color-interactive-primary-hover);
    color: var(--color-text-inverse-hover);
  }

  @include focusOutline(--color-border-focus-primary);
}

.accent {
  background-color: var(--color-ui-highlight);
  color: var(--color-text-dark);

  @include activeOrHover {
    background-color: var(--color-ui-highlight-hover);
  }

  @include focusOutline(--color-border-focus-primary);
}

.disabled,
.disabled:hover {
  background-color: var(--color-background-disabled);
  color: var(--color-text-disabled);
  box-shadow: none;
  cursor: default;
}

.loading:not(.disabled) {
  cursor: default;

  svg,
  span {
    color: transparent !important;
  }
}

.loadingDots {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.allowWrap {
  &.sm {
    height: unset;
    min-height: 36px;
    padding: var(--space-50) var(--space-200);
  }

  &.md {
    height: unset;
    min-height: 48px;
    padding: var(--space-50) var(--space-300);
  }

  &.lg {
    height: unset;
    min-height: 56px;
    padding: var(--space-50) var(--space-300);
  }

  span {
    white-space: unset;
    text-overflow: unset;
    overflow: unset;
  }
}

.link {
  text-decoration: none;
}

.plusBadge {
  position: absolute;
  top: 0;
  right: 0;
}
